"use client";

import styled from "styled-components";
import { Form } from "formik";
import { breakpoint, colors, rem } from "@boxt/design-system";

import { Products } from "@Collections/products";

import { returnBackgroundColor } from "./helpers";

export const Container = styled(Form)`
  position: relative;
  background-color: ${colors.slate.lightest};
  height: ${rem(70)};
  width: 100%;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;

  ${breakpoint("md")} {
    height: 5.875rem;
    padding-left: 0;
  }
`;

export const TooltipContainer = styled.div`
  position: absolute;
  bottom: ${rem(-55)};
  z-index: 1;
  right: 0;
  left: 0;
`;

export const Button = styled.button<{ $backgroundColor?: string | null; $productType?: string }>`
  font-size: 1.125rem;
  line-height: 1.25rem;
  width: 8.3rem;
  min-width: 8.3rem;
  height: 3.75rem;
  background-color: ${({ $backgroundColor, $productType }) =>
    $backgroundColor
      ? $backgroundColor
      : $productType === Products.BoilerRental
        ? colors.coral.normal
        : colors.jade.normal};
  color: ${({ $backgroundColor }) =>
    $backgroundColor?.toLowerCase() === colors.white?.toLowerCase() ? colors.slate.normal : colors.white};
  border-radius: 30px;
  border: none;
  align-self: center;
  cursor: pointer;
  position: absolute;
  right: 0.313rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpoint("md")} {
    right: 1.063rem;
  }

  &:hover {
    background-color: ${({ $backgroundColor, $productType }) => returnBackgroundColor($backgroundColor, $productType)};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
