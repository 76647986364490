"use client";

import styled from "styled-components";
import tinycolor from "tinycolor2";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

import { ReactComponent as questionSVG } from "@Images/icons/question.svg";

export const Container = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  ${includeSpacing({ mt: 1 })}
`;

export const QuestionIcon = styled(questionSVG)`
  height: ${rem(24)};
  width: ${rem(24)};
  ${breakpoint("lg")} {
    height: ${rem(32)};
    width: ${rem(32)};
  }
  color: ${colors.slate.normal};
`;

export const PillButton = styled.button`
  cursor: pointer;
  height: ${rem(32)};
  border-radius: ${rem(16)};
  ${breakpoint("md")} {
    height: ${rem(40)};
    border-radius: ${rem(20)};
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background-color: ${tinycolor(colors.slate.lighter).setAlpha(0.15).toRgbString()};
  &:hover {
    background-color: ${tinycolor(colors.slate.lighter).setAlpha(0.3).toRgbString()};
  }
`;
