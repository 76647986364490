"use client";

import styled from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

export const Container = styled.div`
  height: 100%;

  ${breakpoint("sm")} {
    min-height: ${rem(215)};
  }

  ${breakpoint("lg")} {
    min-height: ${rem(298)};
  }
`;

export const Cover = styled.div`
  display: block;
  height: 100%;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
`;
