"use client";

import styled from "styled-components";
import { Field } from "formik";
import { colors, includeSpacing } from "@boxt/design-system";

export const StyledField = styled(Field)`
  font-size: 1.25rem;
  font-weight: 700;
  text-align: left;
  outline: none;
  border: none;
  color: ${colors.coal.lighter};
  text-transform: uppercase;
  flex: 1;
  text-indent: 0.4rem;
  background: transparent;
  ${includeSpacing({ ml: { md: 1, sm: 2 } })}
  &::placeholder {
    font-weight: 300;
    color: ${colors.coal.lighter};
    text-transform: none;
  }
`;
