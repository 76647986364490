import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { Button, H4, Paragraph } from "@boxt/design-system";

import Image from "@Components/Image";

import { Container, Content, IconText } from "./styles";

export type Props = {
  onContinue: () => void;
  i18nNamespace: string;
};

const Preview = ({ onContinue, i18nNamespace }: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const { t: tCommon } = useTranslation("common");
  const [continueButtonClicked, setContinueButtonClicked] = useState(false);

  const handleClick = () => {
    setContinueButtonClicked(true);
    onContinue();

    const modalContainer: HTMLElement | null = document.querySelector(".intercom-messenger-frame");

    if (modalContainer) {
      modalContainer.style.bottom = "85px";
    }
  };

  return (
    <Container>
      <Content>
        <IconText>
          <Image
            alt="background-image"
            data-testid="solar-preview-marker-icon"
            height={52}
            src="/_next-public/images/solar-preview-marker.svg"
            width={40}
          />
          <div>
            <H4 align="left" bottom={{ md: 0, sm: 2 }}>
              {t("select-address.roof-selected")}
            </H4>
            <Paragraph align="left">{t("select-address.confirmation")}</Paragraph>
          </div>
        </IconText>
        <Button boxtTheme="jade" disabled={continueButtonClicked} isFullWidth onClick={handleClick}>
          {tCommon("actions.continue")}
        </Button>
      </Content>
    </Container>
  );
};

export default Preview;
