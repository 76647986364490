enum Event {
  Answer = "answer",
  Update = "update",
  Back = "back",
  Text = "text",
  GoToUrl = "goToUrl",
  AppendParams = "appendParams",
}

enum StateType {
  Final = "final",
  History = "history",
}

enum Template {
  FreeText = "freetext",
  FilterableList = "filterable_list",
  Info = "info",
  ExpandableList = "expandable_list",
  MapBackground = "map_background",
  UnitOfElectricity = "unit_of_electricity",
  Redirect = "redirect",
}

enum Question {
  AcGeneralProblemDescription = "acGeneralProblemDescription",
  AcFaultCode = "acFaultCode",
  AnnualElectricityUsage = "annualElectricityUsage",
  BatteryOnlyOrSolarWithBattery = "batteryOnlyOrSolarWithBattery",
  BatterySpace = "batterySpace",
  BatteryLocation = "batteryLocation",
  BoilerCoAlarmDescription = "boilerCoAlarmDescription",
  BoilerDescription = "boilerDescription",
  BoilerGasSmellDescription = "boilerGasSmellDescription",
  BoilerGeneralProblemDescription = "boilerGeneralProblemDescription",
  BoilerLocation = "boilerLocation",
  BoilerMake = "boilerMake",
  BoilerType = "boilerType",
  BrokenPlace = "brokenPlace",
  BuyFromBoxt = "buyFromBoxt",
  COAlarmInfo = "coAlarmInfo",
  ConvertCombi = "convertCombi",
  CostOfUnitOfElectricity = "costOfUnitOfElectricity",
  CurrentBoilerLocation = "currentBoilerLocation",
  CurrentlyHaveHomecover = "currentlyHaveHomecover",
  CustomerType = "customerType",
  Distance = "distance",
  EpcReference = "epc",
  EvGeneralProblemDescription = "evGeneralProblemDescription",
  EvFaultCode = "evFaultCode",
  FaultCode = "faultCode",
  FloorArea = "floorArea",
  Fuel = "fuel",
  GasLeakInfo = "gasLeakInfo",
  GoogleRef = "googleRef",
  HasShowersOverBath = "hasShowersOverBath",
  HaveTrvs = "haveTrvs",
  HeatingUsage = "heatingUsage",
  HeatPump = "heatPump",
  HomeLocation = "homeLocation",
  HomeType = "homeType",
  HotWaterUsage = "hotWaterUsage",
  HowOld = "howOld",
  IsIssueSubmitted = "isIssueSubmitted",
  NewAirConLocation = "newAirConLocation",
  NewBoilerLocation = "newBoilerLocation",
  NewLocation = "newLocation",
  NumOfBaths = "numOfBaths",
  NumOfBedrooms = "numOfBedrooms",
  NumOfPanels = "numOfPanels",
  NumOfRadiators = "numOfRadiators",
  NumOfSeperateShowers = "numOfSeparateShowers",
  NumOfShowersOverBath = "numOfShowersOverBath",
  OffStreetParking = "offStreetParking",
  OrderDetails = "orderDetails",
  OutsideLocation = "outsideLocation",
  OwnSolarSystem = "ownSolarSystem",
  Postcode = "postcode",
  PressureDropped = "pressureDropped",
  Reference = "reference",
  Triage = "triage",
  WallMounted = "wallMounted",
  WaterPressure = "waterPressure",
  WhatIsProblemAc = "whatIsProblemAc",
  WhenDidIssueStart = "whenDidIssueStart",
  WhereEvInstalled = "whereInstalled",
  WhereInstalled = "whereInstalled",
}

// answer must have corresponding svg with the name the same
// as the enum value in public/images/screening-icons

enum Answers {
  StandaloneBattery = "standalone_battery",
  AddToSolar = "add_to_solar",
  StandaloneBatterySolarLater = "standalone_battery_solar_later",
}

enum IndeterminateAnswers { // where the icon will always be a question mark
  SomethingElse = "something_else",
  DontKnow = "I don't know",
  SomewhereElse = "somewhere_else",
  Other = "other",
}

enum AnswersWithImages {
  CarPort = "car_port",
  Driveway = "driveway",
  Gas = "gas",
  Lpg = "lpg",
  Combi = "combi",
  Standard = "standard",
  System = "system",
  BackBoiler = "back-boiler",
  Heating = "heating",
  HotWater = "hot_water",
  HeatingAndWater = "heating_and_hot_water",
  Yes = "yes",
  No = "no",
  UpToTwo = "up_to_two_years",
  TwoToFive = "two_to_five_years",
  FiveToTen = "five_to_ten_years",
  TenPlus = "ten_plus_years",
  UtilityRoom = "utility_room",
  Kitchen = "kitchen",
  Garage = "garage",
  AiringCupboard = "airing_cupboard",
  Bedroom = "bedroom",
  Loft = "loft",
  NoHotWater = "no_hot_water",
  HeatingNotWorking = "heating_not_working",
  NoHotWaterAndHeatingNotWorking = "no_hot_water_and_heating_not_working",
  WaterLeak = "water_leak",
  SmellGas = "smell_gas",
  CoAlarm = "co_alarm",
  Installation = "installation",
  Plan = "plan",
  LivingRoom = "living-room",
  LoftOrAttic = "loft-or-attic",
  OpenPlanArea = "open-plan-area",
  MoreThanOneRoom = "more-than-one-room",
  Bathroom = "bathroom",
  ConversionAiringCupboard = "conversion-airing-cupboard",
  Wall = "wall",
  Roof = "roof",
  HomeOwner = "homeowner",
  Renting = "renting",
  Landlord = "landlord",
  Detached = "detached",
  SemiDetached = "semi-detached",
  Terrace = "terrace",
  Flat = "flat",
  Bungalow = "bungalow",
  CommercialOrSmallOffice = "commercial-or-small-office",
  RoomSizeSmall = "small",
  RoomSizeMedium = "medium",
  RoomSizeLarge = "large",
  RoomSizeXlarge = "extra-large",
  Basement = "basement",
  Ground = "ground",
  FirstFloor = "first-floor",
  SecondOrThirdFloor = "second-or-third-floor",
  FourthPlusFloor = "fourth-plus-floor",
  Zero = "0",
  One = "1",
  Two = "2",
  Three = "3",
  Four = "4",
  Five = "5",
  SixOrMore = "6+",
  ZeroToFiveMetres = "0-5 metres",
  SixToFifteenMetres = "6-15 metres",
  SixteenToTwentyFiveMetres = "16-25 metres",
  TwentyFivePlusMetres = "25+",
  ZeroToFiveRads = "0-5 radiators",
  SixToNineRads = "6-9 radiators",
  TenToThirteenRads = "10-13 radiators",
  FourteenToSixteenRads = "14-16 radiators",
  OverSeventeenRads = "Over 17 radiators",
  MoreThanTwoHalfMetres = "more-than-two-half-metres",
  LessThanTwoHalfMetres = "less-than-two-half-metres",
}

const Answer = {
  ...Answers,
  ...IndeterminateAnswers,
  ...AnswersWithImages,
};

// eslint-disable-next-line no-redeclare
type Answer = Answers | IndeterminateAnswers | AnswersWithImages;

export { Event, StateType, Template, Question, Answer, IndeterminateAnswers };
