"use client";

import styled from "styled-components";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

export const Container = styled.div`
  display: none;
  width: ${rem(40)};
  height: ${rem(40)};
  align-self: center;
  color: ${colors.slate.light};

  ${breakpoint("md")} {
    display: block;
  }

  ${includeSpacing({ ml: 4, mr: 2 })}
`;
