"use client";

import styled, { css } from "styled-components";
import { colors } from "@boxt/design-system";

import type { IconTheme } from "./types";

const getThemeColor = ($boxtTheme: IconTheme) => {
  switch ($boxtTheme) {
    case "coral":
      return css`
        color: ${colors.coral.normal};
      `;
    case "slate":
      return css`
        color: ${colors.slate.normal};
      `;
  }
};

export const IconContainer = styled.div<{ $boxtTheme: IconTheme }>`
  width: 100%;
  height: 100%;
  ${({ $boxtTheme }) => getThemeColor($boxtTheme)};
`;
