import Link from "next/link";
import { Button } from "@boxt/design-system";

// children is a stringified JSON object with the following example structure:
// '{"href": "https://www.gov.uk/search-property-infor…try", "buttonText": "GOV.UK", "type": "external"}'
export type Props = {
  children: string;
};

const ButtonLink = ({ children }: Props) => {
  const linkAttributes = JSON.parse(children);

  const href = linkAttributes.href;

  const buttonText = linkAttributes.buttonText;

  const isExternal = linkAttributes.type === "external";

  return isExternal ? (
    <Button boxtTheme="coral" href={href} rel="noopener noreferrer" role="link" skin="link" target="_blank">
      {buttonText}
    </Button>
  ) : (
    <Link href={href} legacyBehavior passHref rel="noopener noreferrer" target="_blank">
      <Button skin="link">{buttonText}</Button>
    </Link>
  );
};

export default ButtonLink;
