import styled, { css } from "styled-components";
import tinyColor from "tinycolor2";
import { Button, colors, Grid, includeSpacing, rem } from "@boxt/design-system";

export const BottomSheetContentGrid = styled.div`
  display: flex;
  overflow: hidden;
`;

export const SliderContainer = styled.div<{ $hasSingleImage: boolean }>`
  background: ${tinyColor(colors.slate.lighter).setAlpha(0.15).toRgbString()};
  border-radius: 12px;
  margin-top: ${rem(4)};
  max-width: ${rem(496)};
  padding: ${({ $hasSingleImage }) => ($hasSingleImage ? `${rem(32)} 0` : `${rem(32)} 0 ${rem(16)} 0`)};
`;

export const SlideWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const StyledGridCol = styled(Grid.Col)`
  ${includeSpacing({
    mb: {
      sm: 6,
    },
  })}
`;

export const DotsStyle = css`
  ${includeSpacing({
    mb: {
      md: 4,
      sm: 4,
    },
    mt: 3,
  })}
`;

export const StyledCTAButton = styled(Button)`
  margin-top: ${rem(17)};
`;
