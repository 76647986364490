import type { CSSProperties } from "react";
import { useCallback, useMemo } from "react";
import { animated, config, useTransition } from "react-spring";
import useTranslation from "next-translate/useTranslation";
import { Button, Grid } from "@boxt/design-system";

import createKey from "@Helpers/createKey";
import type { Event, Question } from "@StateMachines/Screener";
import type { FilterableItem } from "@Hooks/useFilterableItems";
import useFilterableItems from "@Hooks/useFilterableItems";
import { yourBoiler } from "@Locators";
import { ReactComponent as MagGlassSVG } from "@Images/icons/magnifying-glass.svg";
import { ReactComponent as RoundCrossSVG } from "@Images/icons/round-cross.svg";

import Item, { ContainerSpacing as ItemSpacing } from "./components/Item";
import { ClearButton, FilteredContainer, FloatingButton, Input, LastRow, ShowMoreRow, SideIcon } from "./styles";

export type Props = Readonly<{
  answers: FilterableItem[];
  assetCollectionName?: string;
  defaultVisibleItemCount?: number;
  hasSearchFilter?: boolean;
  i18nNamespace: string;
  isAnswering: boolean;
  onAnswer: (question: Question, answer: string, event: Event | undefined, meta: FilterableItem["meta"]) => void;
  question: Question;
  questionParentKey: Question;
}>;

const FilterableList = ({
  answers,
  assetCollectionName,
  defaultVisibleItemCount = 6,
  hasSearchFilter = true,
  i18nNamespace,
  isAnswering,
  onAnswer,
  question,
  questionParentKey,
}: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const { t: tCommon } = useTranslation("common");
  const { filteredItems, outsideListItems, setFilterText, filterText, clearFilterText, expandList, isExpanded } =
    useFilterableItems(answers, defaultVisibleItemCount);
  const handleOnChange = useCallback(({ target: { value } }) => setFilterText(value), [setFilterText]);
  const items = useMemo(() => filteredItems?.map((item, i) => ({ ...item, y: i * ItemSpacing })), [filteredItems]);

  const transitions = useTransition(items, {
    config: { ...config.stiff },
    enter: ({ y }) => ({ opacity: 1, transform: `translateY(${y}px)` }),
    // https://github.com/pmndrs/react-spring/issues/1645
    // todo - check if we still need casting after upgrade is done
    from: { opacity: 0, position: "relative" as CSSProperties["position"] },
    leave: { height: 0, opacity: 0 },
    update: ({ y }) => ({ transform: `translateY(${y}px)` }),
  });

  // This will remove all white space and replace with hyphon and lowercase the value for images
  const cleanValueForImage = (value: string) => createKey(value);
  const getLogo = useCallback(
    (value, hasNoIcon = false) => {
      return assetCollectionName && !hasNoIcon
        ? `/_next-public/images/${assetCollectionName}/${cleanValueForImage(value)}.png`
        : null;
    },
    [assetCollectionName],
  );

  const animatedFragment = transitions((style, { value, meta }, transitionObject) => {
    return (
      <Grid.Row as={animated.div} key={transitionObject.key} style={style}>
        <Grid.Col lg={{ offset: 2, span: 12 }} md={{ offset: 1, span: 6 }} sm={4}>
          <Item
            alt={`screener-${value}`}
            i18nKey={`${i18nNamespace}:answers.${createKey(value)}.text`}
            isAnswering={isAnswering}
            logo={getLogo(value, meta?.hasNoIcon) as string}
            onClick={() => onAnswer(question, value, meta?.event, meta)}
            skin="secondary"
            value={value}
          />
        </Grid.Col>
      </Grid.Row>
    );
  });

  const hasShowMoreButton = !isExpanded && !filterText && defaultVisibleItemCount < answers?.length;

  return (
    <>
      <Grid.Row>
        {hasSearchFilter && (
          <Grid.Col lg={{ offset: 2, span: 12 }} md={{ offset: 1, span: 6 }} sm={4} style={{ position: "relative" }}>
            <SideIcon>
              <MagGlassSVG />
            </SideIcon>
            <Input
              data-testid={yourBoiler.make.input}
              name=""
              onChange={handleOnChange}
              placeholder={t(`questions.${questionParentKey}.filterable_list.placeholder`)}
              type="text"
              value={filterText}
            />
            {filterText && (
              <ClearButton onClick={clearFilterText}>
                <RoundCrossSVG />
              </ClearButton>
            )}
          </Grid.Col>
        )}
      </Grid.Row>
      <FilteredContainer $numberItems={items?.length}>{animatedFragment}</FilteredContainer>
      {hasShowMoreButton && (
        <Grid.Row as={ShowMoreRow}>
          <Grid.Col
            lg={{ offset: 6, span: 4 }}
            md={{ offset: 3, span: 2 }}
            sm={{ offset: 1, span: 2 }}
            style={{ textAlign: "center" }}
          >
            <Button as={FloatingButton} boxtTheme="jade" onClick={expandList} testId={yourBoiler.make.moreButton}>
              {tCommon("show-more")}
            </Button>
          </Grid.Col>
        </Grid.Row>
      )}
      {!filterText &&
        outsideListItems?.map(({ value, meta }) => (
          <Grid.Row as={LastRow} key={createKey(value)}>
            <Grid.Col lg={{ offset: 2, span: 12 }} md={{ offset: 1, span: 6 }} sm={4}>
              <Item
                alt={`screener-${value}`}
                i18nKey={`${i18nNamespace}:answers.${createKey(value)}.text`}
                isAnswering={isAnswering}
                logo={getLogo(value, meta?.hasNoIcon) as string}
                onClick={() => onAnswer(question, value, meta?.event, meta)}
                skin="primary"
                value={value}
              />
            </Grid.Col>
          </Grid.Row>
        ))}
    </>
  );
};

export default FilterableList;
