import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { Button, H4, OrderedList, Paragraph, UnorderedList } from "@boxt/design-system";

import CONTACT_DETAILS from "@Collections/contactDetails";
import { BOXT_CUSTOMER_URLS, REPAIR_URLS } from "@Collections/routes";
import Image from "@Components/Image";
import useChat from "@Hooks/useChat";

import { BulletPoint, ImageWrapper, InfoContainer } from "./styles";

export type Props = {
  i18n: {
    question: string;
    namespace: string;
  };
};

const HelpInfo = ({ i18n: { namespace, question } }: Props) => {
  const { openChat } = useChat();
  const { t } = useTranslation(namespace);

  const translateObject: Record<string, string> = t(`questions.${question}`, {}, { returnObjects: true });
  const images: Record<string, string>[] | null =
    (translateObject?.sideDrawerImages as unknown as Record<string, string>[]) || null;

  const hasImages = Array.isArray(images) && images.length > 0;
  return (
    <InfoContainer>
      <Trans
        components={{
          bp: <BulletPoint />,
          fullList: (
            <Button
              boxtTheme="coral"
              href={`${REPAIR_URLS.LANDING}#prices`}
              rel="noopener noreferrer"
              skin="link"
              target="_blank"
            />
          ),
          landing: (
            <Button
              boxtTheme="coral"
              href={REPAIR_URLS.LANDING}
              rel="noopener noreferrer"
              skin="link"
              target="_blank"
            />
          ),
          li: <li />,
          livechat: <Button boxtTheme="coral" onClick={openChat} skin="link" />,
          number: <Button boxtTheme="coral" href={`tel://${CONTACT_DETAILS.PHONE.replace(/\s/g, "")}`} skin="link" />,
          ol: <OrderedList boxtTheme="slate" />,
          p: <Paragraph align="left" as="div" bottom={4} boxtTheme="slate" />,
          questionnaire: (
            <Button
              boxtTheme="coral"
              href={BOXT_CUSTOMER_URLS.BOILER}
              rel="noopener noreferrer"
              skin="link"
              target="_blank"
            />
          ),
          r: <Paragraph as="span" boxtTheme="coral" />,
          s: <strong />,
          span: <span />,
          title: <H4 align="left" bottom={6} boxtTheme="slate" />,
          ul: <UnorderedList boxtTheme="slate" />,
        }}
        i18nKey={`${namespace}:questions.${question}.sideDrawerText`}
      />
      {hasImages && (
        <ImageWrapper>
          {images.map(({ image, alt }, index) => (
            <Image alt={alt} height={250} key={`${alt}-${index}`} src={image} width={250} />
          ))}
        </ImageWrapper>
      )}
    </InfoContainer>
  );
};

export default HelpInfo;
