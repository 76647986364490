import { useCallback } from "react";
import type { KeenSliderInstance } from "keen-slider/react";

import { Arrow, ArrowsContainer } from "@Components/pages/common/Slider/Arrows/styles";
import type { ArrowTheme } from "@Components/pages/common/Slider/types";
import { ReactComponent as ArrowIcon } from "@Images/arrow.svg";

export type Props = {
  arrowTheme: ArrowTheme;
  activeSlide: number;
  sliderInstance: KeenSliderInstance | null;
  showLeftArrow?: boolean;
  hideOnMobile?: boolean;
};

const Arrows = ({ arrowTheme, activeSlide, sliderInstance, showLeftArrow = false, hideOnMobile = true }: Props) => {
  const handleArrowRightClick = useCallback(() => sliderInstance?.next(), [sliderInstance]);
  const handleArrowLeftClick = useCallback(() => sliderInstance?.prev(), [sliderInstance]);
  const isLoop = Boolean(sliderInstance?.options?.loop);
  const isRightArrowHidden = activeSlide >= Number(sliderInstance?.track?.details?.maxIdx) && !isLoop;
  const isLeftArrowHidden = (isLoop || activeSlide === 0) && !showLeftArrow;

  return (
    <ArrowsContainer $hideOnMobile={hideOnMobile} data-testid="slider-arrows-container">
      <Arrow
        $arrowTheme={arrowTheme}
        $direction="right"
        $hidden={isRightArrowHidden}
        data-testid="right-arrow"
        onClick={handleArrowRightClick}
      >
        <ArrowIcon />
      </Arrow>
      <Arrow
        $arrowTheme={arrowTheme}
        $direction="left"
        $hidden={isLeftArrowHidden}
        data-testid="left-arrow"
        onClick={handleArrowLeftClick}
      >
        <ArrowIcon />
      </Arrow>
    </ArrowsContainer>
  );
};

export default Arrows;
