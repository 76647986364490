import type { ForwardedRef } from "react";
import { forwardRef } from "react";
import { useFormikContext } from "formik";
import { breakpoints } from "@boxt/design-system";

import useMedia from "@Hooks/useMedia";
import { landing } from "@Locators";

import { StyledField } from "./styles";

export interface Placeholder {
  sm: string | undefined;
  md: string | undefined;
}

export type Props = {
  placeholder: Placeholder;
  onFocus?: () => void;
};

const Input = forwardRef(({ placeholder, onFocus, ...rest }: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const { submitCount, resetForm, values, handleChange } = useFormikContext();
  const isWiderScreen = useMedia(`(min-width: ${breakpoints.md.width})`);

  const handleOnChange = (e) => {
    e.preventDefault();

    if (submitCount > 0) {
      const { value } = e.target;
      resetForm({ values: { postcode: value } });
    } else {
      handleChange(e);
    }
  };

  const handleFocus = () => {
    resetForm({ values });
    onFocus?.();
  };

  // resetForm on focus, keeping values to resetting submitCount
  // errors will only display after a submission attempt, resetting
  // the form will hide an error until the next submission attempt
  return (
    <StyledField
      data-testid={landing.postcode.input}
      name="postcode"
      onChange={handleOnChange}
      onFocus={handleFocus}
      placeholder={isWiderScreen ? placeholder.md : placeholder.sm}
      {...rest}
      innerRef={ref}
    />
  );
});

export default Input;
