"use client";

import type { CSSProp, RuleSet } from "styled-components";
import styled, { css } from "styled-components";
import { breakpoint, Button, colors, includeSpacing, rem, Spacing } from "@boxt/design-system";

import { ReactComponent as Dot } from "@Images/icons/dot.svg";

export const Wrapper = styled.div<{ $cssObject: CSSProp }>`
  display: flex;
  overflow: hidden;
  ${({ $cssObject }) =>
    $cssObject &&
    css`
      ${$cssObject};
    `};
`;

export const StyledSpacing = styled(Spacing)`
  position: relative;
`;

export const Icon = styled(Dot)<{ $selected: boolean }>`
  cursor: pointer;
  margin-right: ${rem(10)};
  color: ${({ $selected }) => ($selected ? colors.coral.normal : colors.slate.lighter)};
  opacity: ${({ $selected }) => ($selected ? 1 : 0.5)};
`;

export const IconWrapper = styled.div<{ $alwaysShowDots: boolean; $cssStyle: RuleSet<object> }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${includeSpacing({
    mb: { md: 0, sm: 2 },
    mt: { md: 2, sm: 1 },
  })}
  min-height: ${rem(25)};

  ${breakpoint("lg")} {
    ${({ $alwaysShowDots }) => !$alwaysShowDots && "display: none"}
  }
  ${({ $cssStyle }) => $cssStyle}
`;

export const ContentWrapper = styled.div<{ $isOverflow?: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  ${({ $isOverflow }) =>
    $isOverflow &&
    css`
      .keen-slider[data-keen-slider-moves] a {
        pointer-events: none;
      }

      .keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
        min-height: initial;
        overflow: initial;
        position: initial;
        width: initial;
      }

      .keen-slider:not([data-keen-slider-disabled]) {
        overflow: visible;
      }
    `}
`;

export const StyledButton = styled(Button)`
  display: block;
  margin: ${rem(32)} auto 0 auto;
  width: ${rem(290)};
`;
