import type { Question } from "@StateMachines/Screener/constants";

import Preview from "src/components/Map/Preview";
import SelectYourRoof from "src/components/Map/SelectYourRoof";

import type { MapStage } from "./types";

export type Props = {
  mapCoordinates: google.maps.LatLng;
  mapStage: MapStage;
  onAnswer: (question: Question, mapCoordinates: string) => void;
  setMapStage: (stage: MapStage) => void;
  question: Question;
  i18nNamespace: string;
};

const MapBackground = ({ mapCoordinates, mapStage, onAnswer, question, setMapStage, i18nNamespace }: Props) => {
  const handleConfirmLocation = () => {
    onAnswer(question, JSON.stringify({ lat: mapCoordinates.lat(), lng: mapCoordinates.lng() }));
  };

  return (
    <>
      {mapStage === "selectYourRoof" && <SelectYourRoof onConfirm={() => setMapStage("clickToSelect")} />}
      {mapStage === "preview" && <Preview i18nNamespace={i18nNamespace} onContinue={handleConfirmLocation} />}
    </>
  );
};

export default MapBackground;
