import useTranslation from "next-translate/useTranslation";
import { useFormikContext } from "formik";
import type { FontTheme } from "@boxt/design-system";

import Tooltip from "@Components/utils/Tooltip";

import { TooltipContainer } from "../../styles";

export type Props = {
  infoKey?: string;
  tooltipBgColor?: string;
  tooltipTextTheme?: FontTheme;
  i18nNamespace?: string;
};

const Tip = ({ infoKey, tooltipBgColor, tooltipTextTheme, i18nNamespace }: Props) => {
  const { t } = useTranslation();
  const { errors, submitCount } = useFormikContext<{ postcode: string }>();
  const key = (submitCount > 0 && errors.postcode) || infoKey;

  return (
    <>
      {key && (
        <TooltipContainer>
          <Tooltip
            bgColor={tooltipBgColor}
            text={t(`${i18nNamespace || "common:"}postcode.info.${key}`)}
            textTheme={tooltipTextTheme}
          />
        </TooltipContainer>
      )}
    </>
  );
};

export default Tip;
