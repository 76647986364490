import DesktopView from "./DesktopView";
import MobileView from "./MobileView";

export type Props = {
  onConfirm: () => void;
};

const SelectYourRoof = ({ onConfirm }: Props) => {
  return (
    <>
      <MobileView onConfirm={onConfirm} />
      <DesktopView onConfirm={onConfirm} />
    </>
  );
};

export default SelectYourRoof;
