"use client";

import styled from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, colors, rem } from "@boxt/design-system";

export const RadioContainer = styled.div`
  border-radius: 6px;
  padding: ${rem(24)} ${rem(16)};
  background-color: ${tinyColor(colors.sky.lighter).setAlpha(0.1).toRgbString()};
  border: 1px solid ${colors.sky.lighter};

  ${breakpoint("md")} {
    padding: ${rem(24)};
  }
`;

export const RadioInnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint("md")} {
    flex-direction: row;
  }
`;

export const ButtonContainer = styled.div`
  max-width: ${rem(225)};
  margin: 0 auto;
`;
