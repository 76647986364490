import type { ComponentType, ReactNode } from "react";
import type { IStyledComponent } from "styled-components";
import { colors } from "@boxt/design-system";

const DefaultWrapper = ({ children }: { children: string }) => {
  return <span style={{ color: colors.coral.normal }}>{children}</span>;
};

type WrapperType = ({ children }: { children: string }) => JSX.Element;

const withCustomWrapper = (
  Component: ComponentType<{ children: ReactNode }> | IStyledComponent<"web", any>,
  Wrapper: WrapperType = DefaultWrapper,
) => {
  const renderWithWrapper = ({ children, ...props }) => {
    const wrapped = ([text, content, ...next]: string[], completed: (string | JSX.Element)[] = []) => {
      if (!text && !next) {
        return completed;
      }
      if (!content) {
        return [...completed, text];
      }
      return wrapped(next, [
        ...completed,
        text,
        <Wrapper {...props} key={content}>
          {content}
        </Wrapper>,
      ]);
    };

    return <Component {...props}>{wrapped(children.split("#"))}</Component>;
  };

  return renderWithWrapper;
};

export default withCustomWrapper;
