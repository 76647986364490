"use client";

import styled from "styled-components";
import { breakpoint, colors, rem } from "@boxt/design-system";

export const Container = styled.div`
  bottom: ${rem(120)};
  position: absolute;
  right: calc(50% - ${rem(150)});
  width: ${rem(300)};
  z-index: 1;
  ${breakpoint("md")} {
    right: calc(50% - ${rem(295)});
    width: ${rem(590)};
  }
`;

export const Content = styled.div`
  align-items: center;
  background: ${colors.white};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  padding: ${rem(16)};

  ${breakpoint("md")} {
    display: grid;
    grid-template-columns: 1fr 135px;
    flex-direction: row;
  }
`;

export const IconText = styled.div`
  align-items: flex-start;
  display: flex;
  gap: ${rem(16)};
  ${breakpoint("md")} {
    align-items: center;
  }
`;
