"use client";

import styled from "styled-components";
import { breakpoints, rem } from "@boxt/design-system";

export const FreeTextWrapper = styled.div`
  max-width: ${rem(808)};
  margin: 0 auto;
  @media (max-width: ${breakpoints.md.width}) {
    button {
      width: 100%;
    }
    form {
      margin: 0 auto;
      padding: 0;
      div {
        padding: 0;
      }
    }
  }
  div {
    box-shadow: none;
    input {
      margin: 0;
    }
  }
`;
