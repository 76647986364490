import styled from "styled-components";
import type { FontTheme, Weight } from "@boxt/design-system";
import { colors } from "@boxt/design-system";

import { BACKGROUND_COLORS } from "@Collections/themeColors";

export const StyledLi = styled.li<{ $boxtTheme?: FontTheme; $weight?: Weight }>`
  color: ${({ $boxtTheme }) => ($boxtTheme ? BACKGROUND_COLORS[$boxtTheme] : colors.coal.lighter)};
  font-weight: ${({ $weight }) => {
    if ($weight === "bold") {
      return "700";
    } else if ($weight === "light") {
      return "300";
    } else {
      return "400";
    }
  }};
`;
