import styled from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

export const Container = styled.div`
  padding: 0 ${rem(16)};

  ${breakpoint("md")} {
    padding: 0;
  }
`;
