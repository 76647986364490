import type { ReactElement } from "react";
import useTranslation from "next-translate/useTranslation";
import { Grid, Paragraph, Spacing } from "@boxt/design-system";

import { StyledGridCol } from "./styles";

export type Props = {
  children: ReactElement;
  hasAdditionalInfo?: boolean;
  i18nNamespace: string;
};

const Info = ({ children, hasAdditionalInfo = false, i18nNamespace }: Props) => {
  const { t } = useTranslation(i18nNamespace);

  return (
    <Grid.Row>
      <StyledGridCol>
        {children}
        {hasAdditionalInfo && (
          <Spacing mh={{ md: 0, sm: 3 }} mt={4}>
            <Paragraph align="center" boxtTheme="slate">
              {t("additional-info")}
            </Paragraph>
          </Spacing>
        )}
      </StyledGridCol>
    </Grid.Row>
  );
};
export default Info;
