import { Question } from "@StateMachines/Screener";

import { ProductType, ScreenerVariant } from "./types";

export enum Action {
  Answer = "ANSWER",
  Back = "BACK",
  Init = "INIT",
}

export const screenerProductType = {
  [ScreenerVariant.BoilerRepair]: ProductType.BoilerRepair,
  [ScreenerVariant.Heatlink]: ProductType.Heatlink,
  [ScreenerVariant.HomeCover]: ProductType.HomeCover,
  [ScreenerVariant.HomeServe]: ProductType.HomeCover,
  [ScreenerVariant.BoilerSubscription]: ProductType.BoilerSubscription,
  [ScreenerVariant.BoilerInstallation]: ProductType.BoilerInstallation,
  [ScreenerVariant.BoilerInstallationExperiment]: ProductType.BoilerInstallation,
  [ScreenerVariant.Solar]: ProductType.SolarInstallation,
  [ScreenerVariant.EVCharger]: ProductType.EV,
  [ScreenerVariant.HeatPump]: ProductType.HeatPump,
  [ScreenerVariant.AirCondition]: ProductType.AC,
  [ScreenerVariant.BatteryOnly]: ProductType.BatteryOnly,
};

export const screenerQueryMap = {
  [Question.GoogleRef]: "caseNumber",
};

export const SCREENER_COOKIE_KEY = "boxt_screener_key";

export const PURCHASE_TO_RENTAL_QUERY = "plpToBoxtLife";
