"use client";

import styled from "styled-components";
import { colors, includeSpacing } from "@boxt/design-system";

import { ReactComponent as resetSVG } from "@Images/icons/reset.svg";

export const ResetIcon = styled(resetSVG)`
  color: ${colors.white};
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    ${includeSpacing({ mt: 1 })}
  }
`;
