import { useCallback, useState } from "react";
import getConfig from "next/config";
import { useRouter } from "next/router";

import nextApi from "@DataAccess/local";

import loadIntercomScript from "./intercomScript";

const {
  publicRuntimeConfig: { INTERCOM_APP_ID, INTERCOM_API_BASE },
} = getConfig();

const useChat = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMaximised, setIsMaximised] = useState(false);

  const { query, push, pathname } = useRouter();
  const token = query?.token;

  const load = useCallback(
    async <T>(...rest): Promise<T | null> => {
      return new Promise((resolve) => {
        if (typeof window !== "undefined" && !window?.Intercom?.booted) {
          loadIntercomScript({
            appId: INTERCOM_APP_ID,
            onLoad: async () => {
              if (typeof window !== "undefined") {
                const intercomSettings: typeof window.intercomSettings = {
                  api_base: INTERCOM_API_BASE,
                  app_id: INTERCOM_APP_ID,
                  custom_launcher_selector: ".boxt-custom-intercom-launcher",
                  hide_default_launcher: true,
                  horizontal_padding: 20,
                  vertical_padding: 85,
                };

                if (token) {
                  try {
                    const {
                      data: { email, id },
                    } = await nextApi.post("/validate-intercom-user", { params: { token } });

                    intercomSettings.user_id = id;
                    intercomSettings.email = email;
                  } catch {
                    /* empty */
                  }

                  window?.Intercom("boot", intercomSettings);

                  delete query.token;

                  await push(
                    {
                      hash: window.location.hash,
                      pathname,
                      query,
                    },
                    undefined,
                    { shallow: true },
                  );
                  if (rest) {
                    window.Intercom(...rest);
                  }
                  window?.Intercom("showNewMessage");
                  setIsLoaded(true);
                  return resolve(null);
                }

                window?.Intercom("boot", intercomSettings);
                setIsLoaded(true);
                return resolve(rest ? window.Intercom(...rest) : "");
              }
            },
          });
        } else if (typeof window !== "undefined") {
          setIsLoaded(true);
          return resolve(rest ? window.Intercom(...rest) : "");
        }
      });
    },
    [pathname, push, query, token],
  );

  const update = async (data: Record<string, string>) => await load<void>("update", data);

  const trackEvent = async (eventName: string, metaDate: Record<string, string>) =>
    await load<void>("trackEvent", eventName, metaDate);

  const getVisitorId = async (): Promise<string | null> => await load<string>("getVisitorId");

  const onShow = async (callBack: () => void) => await load<void>("onShow", callBack);

  const openChat = async () => await load<void>("showNewMessage");

  const minimize = async () => await load<void>("hide");

  const setMaximised = () => {
    setIsMaximised((curr) => !curr);
  };

  return {
    getVisitorId,
    hide: minimize,
    isLoaded,
    isMaximised,
    load,
    minimize,
    onShow,
    openChat,
    setMaximised,
    trackEvent,
    update,
  };
};

export default useChat;
