"use client";

import styled from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, colors, includeSpacing } from "@boxt/design-system";

import { ContainerHeight as ItemHeight, ContainerSpacing as ItemSpacing } from "./components/Item";

export const Input = styled.input`
  ${includeSpacing({ mb: 2 })};
  width: 100%;
  height: 3.75rem;
  border: none;
  border-bottom: solid 0.5px ${tinyColor(colors.slate.lighter).setAlpha(0.2).toRgbString()};
  background: none;
  font-size: 1.25rem;
  padding-left: 2rem;
  color: ${colors.slate.normal};

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.slate.lighter};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.slate.lighter};
  }

  ${breakpoint("md")} {
    font-size: 1.375rem;
    padding-left: 3.75rem;
  }

  ${breakpoint("lg")} {
    font-size: 1.5rem;
  }

  &:focus {
    outline: none;
  }
`;

export const SideIcon = styled.div`
  display: flex;
  height: 3.75rem;
  width: 2rem;
  position: absolute;

  ${breakpoint("md")} {
    width: 3.75rem;
    padding: 0.5rem;
  }

  svg {
    height: 100%;
    width: 100%;
    path {
      fill: ${colors.slate.lighter};
    }
  }
`;

const ButtonHeight = 3;
export const FloatingButton = styled.button`
  height: ${ButtonHeight}rem;
  box-shadow: 0px 10px 10px rgba(51, 69, 87, 0.08);
  z-index: 100;
  ${includeSpacing({ mb: 2 })};
`;

export const ClearButton = styled.button`
  height: 1.875rem;
  width: 1.875rem;
  border: none;
  background: transparent;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: calc(1.875rem / 2);
  padding: 0;
  cursor: pointer;

  svg {
    path {
      fill: ${colors.slate.lighter};
    }
  }
`;

export const LastRow = styled.div`
  padding-bottom: 5rem;
`;

export const ShowMoreRow = styled.div`
  margin-top: calc(-${ButtonHeight}rem / 2 - ${ItemSpacing}px);
  position: relative;
  z-index: 1;
`;

export const FilteredContainer = styled.div<{ $numberItems: number }>`
  height: ${({ $numberItems }) => $numberItems * (ItemHeight + ItemSpacing)}px;
`;
