import { useState } from "react";
import { useRouter } from "next/compat/router";
import useTranslation from "next-translate/useTranslation";
import { destroyCookie } from "nookies";
import { Button, H4, Paragraph, PopUp } from "@boxt/design-system";

import { Products } from "@Collections/products";
import { HOME_SERVE_URLS, ORDER_URLS, SCREENING_URLS } from "@Collections/routes";
import { LoadingIcon } from "@Components/pages/photos";
import { SCREENER_COOKIE_KEY } from "@Pages/api/_next/screeners/_constants";
import { ScreenerVariant } from "@Pages/api/_next/screeners/types";
import { HOMECOVER_ACQUISITION_TYPE_KEY, HOMECOVER_COOKIE_KEY } from "@Pages/api/_next/subscriptions/verify/constants";

import { MessageWrapper, ResetIcon } from "./styles";

export type Props = {
  onClose: () => void;
  screenerVariant: ScreenerVariant;
  i18nNamespace: string;
};

const BackMessage = ({ screenerVariant, onClose, i18nNamespace }: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const [isResetting, setIsResetting] = useState(false);
  const router = useRouter();

  const handleReset = () => {
    setIsResetting(true);
    destroyCookie(null, `${SCREENER_COOKIE_KEY}:${screenerVariant}`, { path: "/" });
    destroyCookie(null, `${HOMECOVER_COOKIE_KEY}`, { path: "/" });
    destroyCookie(null, `${HOMECOVER_ACQUISITION_TYPE_KEY}`, { path: "/" });

    const airConQuery =
      router?.query["nextjs-ac-journey"] !== undefined
        ? {
            "nextjs-ac-journey": router?.query["nextjs-ac-journey"],
          }
        : {};

    // SINCE WE ARE CHECKING FOR THE SCREENING COOKIE, WE NEED TO GO TO THE SCREENING LANDING PAGE
    const screenerVariantToRoute = {
      [ScreenerVariant.AirCondition]: {
        pathname: ORDER_URLS[Products.AC].LANDING,
        query: airConQuery,
      },
      [ScreenerVariant.BoilerInstallation]: ORDER_URLS[Products.Boiler].LANDING,
      [ScreenerVariant.HomeServe]: HOME_SERVE_URLS.LANDING,
      [ScreenerVariant.BatteryOnly]: ORDER_URLS[Products.BatteryOnly].LANDING,
    };

    const nextRoute = screenerVariantToRoute[screenerVariant] || SCREENING_URLS[screenerVariant].start;
    router?.push(nextRoute);
  };

  return (
    <PopUp ariaLabel={{ "aria-label": "popup-rest-screener" }} onDismiss={onClose}>
      <MessageWrapper>
        <H4>{t("back-message.title")}</H4>
        <Paragraph align="center" bottom={4} boxtTheme="slate" top={2}>
          {t("back-message.text")}
        </Paragraph>
        <Button
          boxtTheme="jade"
          icon={{
            afix: "left",
            size: "medium",
            svg: <ResetIcon />,
          }}
          isFullWidth
          loadingConfig={{
            icon: {
              afix: "left",
              size: "medium",
              svg: <LoadingIcon />,
            },
            isLoading: isResetting,
          }}
          onClick={handleReset}
          size="medium"
        >
          {t("back-message.reset-button")}
        </Button>
        <Button boxtTheme="jade" isFullWidth onClick={onClose} size="medium" skin="secondary">
          {t("back-message.close-button")}
        </Button>
      </MessageWrapper>
    </PopUp>
  );
};

export default BackMessage;
