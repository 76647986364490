"use client";

import styled, { css } from "styled-components";
import tinyColor from "tinycolor2";
import { colors, Paragraph } from "@boxt/design-system";

const skins = {
  primary: css`
    background: transparent;
    box-shadow: 0 10px 10px rgba(51, 69, 87, 0.08);
    border-color: ${colors.white};
  `,
  secondary: css`
    background: ${colors.white};
    border: solid 3px ${tinyColor(colors.slate.lighter).setAlpha(0.4).toRgbString()};
    box-shadow: none;
  `,
};

export const ContainerHeight = 75;
export const ContainerSpacing = 16;
export const Container = styled.button<{ skin: "primary" | "secondary" }>`
  height: ${ContainerHeight}px;
  width: 100%;
  border-radius: 6px;
  border: solid 1px;
  display: flex;
  align-items: center;
  transition:
    border-color 0.2s ease,
    border 0.3s ease;
  cursor: pointer;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  text-align: left;

  &:hover,
  &:focus {
    border-color: ${colors.coral.normal};
  }

  ${({ skin }) => skins[skin]}
`;

export const Name = styled(Paragraph)`
  padding-left: 1rem;
`;

export const Red = styled.span`
  color: ${colors.coral.normal};
`;
