import { ReactComponent as LocationSVG } from "@Images/icons/location-solid.svg";

import { Container } from "./styles";

const Icon = () => (
  <Container>
    <LocationSVG />
  </Container>
);

export default Icon;
