"use client";

import styled from "styled-components";
import { breakpoint, Button, colors, rem } from "@boxt/design-system";

export const Wrapper = styled.div`
  display: none;

  ${breakpoint("md")} {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100;
  }
`;

export const Container = styled.div`
  display: none;

  ${breakpoint("md")} {
    background: ${colors.white};
    border-radius: 12px;
    display: block;
    width: ${rem(630)};
    z-index: 1;
  }
  ${breakpoint("lg")} {
    width: ${rem(1024)};
  }
`;

export const Content = styled.div`
  display: grid;
  gap: ${rem(16)};
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
`;

export const ImageContainer = styled.div`
  ${breakpoint("md")} {
    border-radius: 12px;
    height: ${rem(155)};
    overflow: hidden;
    position: relative;
    width: ${rem(189)};
  }
  ${breakpoint("lg")} {
    height: ${rem(190)};
    width: ${rem(314)};
  }
`;

export const StyledButton = styled(Button)`
  display: block;
  margin: 0 auto;
  width: ${rem(320)};
`;
