"use client";

import styled from "styled-components";
import type { FontTheme } from "@boxt/design-system";
import { colors, includeSpacing } from "@boxt/design-system";

import { BACKGROUND_COLORS } from "@Collections/themeColors";

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const BulletPoint = styled.span<{ $boxtTheme?: FontTheme }>`
  color: ${({ $boxtTheme }) => ($boxtTheme ? BACKGROUND_COLORS[$boxtTheme] : colors.coral.normal)};
  ${includeSpacing({ mr: 2 })}
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${includeSpacing({ mt: 4 })}
`;
