import { Form, Formik } from "formik";
import { number, object, string } from "yup";

import type { Question } from "@StateMachines/Screener";

import UnitOfElectricityForm from "./components/UnitOfElectricityForm";
import { Container } from "./styles";

export type Props = {
  onAnswer: (question: Question, answer: string) => void;
  question: Question;
  questionParentKey: Question;
  i18nNamespace: string;
};

type DifferentRateAtNightValue = "yes" | "no";

export type UnitOfElectricityValues = {
  differentRateAtNight: DifferentRateAtNightValue;
  unitPrice: string;
  dayUnitPrice: string;
  nightUnitPrice: string;
};

export const validationSchema = object().shape({
  dayUnitPrice: number().when("differentRateAtNight", {
    is: (type: DifferentRateAtNightValue) => type === "yes",
    then: (schema) => schema.required("required").moreThan(-1, "positive"),
  }),
  differentRateAtNight: string().oneOf(["yes", "no"]).required(),
  nightUnitPrice: number().when("differentRateAtNight", {
    is: (type: DifferentRateAtNightValue) => type === "yes",
    then: (schema) => schema.required("required").moreThan(-1, "positive"),
  }),
  unitPrice: number().when("differentRateAtNight", {
    is: (type: DifferentRateAtNightValue) => type === "no",
    then: (schema) => schema.required("required").moreThan(-1, "positive"),
  }),
});

const UnitOfElectricity = ({ onAnswer, question, questionParentKey, i18nNamespace }: Props) => {
  const initialValues: UnitOfElectricityValues = {
    dayUnitPrice: "",
    differentRateAtNight: "no",
    nightUnitPrice: "",
    unitPrice: "",
  };

  const handleOnSubmit = (values: UnitOfElectricityValues) => {
    onAnswer(question, JSON.stringify(values));
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validateOnMount validationSchema={validationSchema}>
      {({ handleSubmit, isValid, isSubmitting }) => (
        <Form>
          <Container>
            <UnitOfElectricityForm
              i18nNamespace={i18nNamespace}
              isAnswering={isSubmitting}
              isValid={isValid}
              onSubmit={handleSubmit}
              questionParentKey={questionParentKey}
            />
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default UnitOfElectricity;
