import type { Answer } from "@StateMachines/Screener";
import { IndeterminateAnswers } from "@StateMachines/Screener";

import { IconContainer } from "./styles";
import type { IconTheme } from "./types";

export type Props = {
  answer: Answer;
  iconName?: string | null;
  theme?: IconTheme;
};

const Icon = ({ answer, iconName, theme = "coral" }: Props) => {
  const icon =
    iconName || ((Object.values(IndeterminateAnswers) as string[]).includes(answer) ? "question-mark" : answer);

  const { ReactComponent } = require(`@Images/screening-icons/${icon}.svg`);
  return (
    <IconContainer $boxtTheme={theme}>
      <ReactComponent />
    </IconContainer>
  );
};

export default Icon;
