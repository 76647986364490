import { isEmpty } from "lodash-es";
import { colors } from "@boxt/design-system";

import { Products } from "@Collections/products";

import { themes } from "src/constants/themes";

export const returnBackgroundColor = (color?: string | null, productType?: string) => {
  const theme = Object.keys(themes)
    .map((theme) => {
      return themes[theme].color === color ? theme : null;
    })
    .filter((theme) => theme);
  return !isEmpty(theme) && theme[0]
    ? themes[theme[0]].backgroundColor
    : productType === Products.BoilerRental
      ? colors.coral.dark
      : colors.jade.dark;
};
