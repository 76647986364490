import type { Event, Question } from "@StateMachines/Screener";
import type { FilterableItem } from "@Hooks/useFilterableItems";

import FilterableList from "../FilterableList";

export type Props = Readonly<{
  answers: FilterableItem[];
  assetCollectionName?: string;
  defaultVisibleItemCount: number;
  i18nNamespace: string;
  isAnswering: boolean;
  onAnswer: (question: Question, answer: string, event: Event, meta: FilterableItem["meta"]) => void;
  question: Question;
  questionParentKey: Question;
}>;

const ExpandableList = ({
  answers,
  assetCollectionName,
  defaultVisibleItemCount,
  onAnswer,
  question,
  questionParentKey,
  i18nNamespace,
  isAnswering,
}: Props) => (
  <FilterableList
    answers={answers}
    assetCollectionName={assetCollectionName}
    defaultVisibleItemCount={defaultVisibleItemCount}
    hasSearchFilter={false}
    i18nNamespace={i18nNamespace}
    isAnswering={isAnswering}
    onAnswer={onAnswer}
    question={question}
    questionParentKey={questionParentKey}
  />
);

export default ExpandableList;
